import * as React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

const GlossaryPage = () => {
  const letters = '#ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')

  const definitions = {
    '#': [
      {
        word: '12-Hour Recorder (12-Hour Register)',
        definition: 'A sub-dial on a chronograph that can record time periods of up to 12 hours.',
      },
      {
        word: '30-Minute Recorder (30-Minute Register)',
        definition: 'A sub-dial on a chronograph that can record time periods of up to 30 minutes.',
      },
    ],
    A: [
      { word: 'Alarm', definition: 'The watch alerts you with beeps at a pre-set time.' },
      {
        word: 'Analog',
        definition: 'A watch that shows the time using hour and minute hands.',
      },
      {
        word: 'Anti-magnetic',
        definition:
          'Refers to a watches ability to withstand outside magnetic influences (based on its design and materials used in manufacturing). All watches can be influenced by magnetic fields if strong enough.',
      },
      {
        word: 'Automatic windin',
        definition:
          'This refers to a watch fitted with a complication or feature which winds the mainspring with the motion of the wearer\'s wrist.',
      },
    ],
    B: [
      {
        word: 'Balance wheel',
        definition:
          'A specialized compound wheel which by oscillating regulates the timing fast or slow of the watch or clock.',
      },
      {
        word: 'Band Width',
        definition:
          'Measures the distance between the case lugs. Therefore, band width may not necessarily be the exact width of the watch band, as a bracelet or strap can have tapering widths. When swapping watch bands or purchasing replacement bands, the band width of the new band must match the distance between the case lug.',
      },
      {
        word: 'Battery',
        definition:
          'A dry cell similar to that used in a flashlight which is used to power electric and electronic watches. (Example quartz watch).',
      },
      {
        word: 'Battery End-Of-Life Indicator (EOL)',
        definition:
          'A function that signals an impending battery failure in a Quartz watch by means of the second hand jumping in two- or four-second intervals. The watch has approximately two weeks before battery failure.',
      },
      {
        word: 'Bezel',
        definition:
          'The ring that surrounds the dial, or face, of a watch. Commonly constructed of gold, gold plate or stainless steel, the bezel may be adorned with diamonds on a jewelry watch. On sports watches, it often has calibrated markings and the ability to rotate in either one or two directions. The primary purpose of the bezel is to hold the crystal covering the face of the watch in place.',
      },
      {
        word: 'Bi-directional Rotating Bezel',
        definition:
          'A bezel that can be used for mathematical calculations or keeping track of elapsed time through either a clockwise or counterclockwise movement.',
      },
      {
        word: 'Bridge',
        definition:
          'A bridge is a metal plate that contains the “jewels” affixed to the main plate which forms the frame of the movement and holds the rotating gears. It’s similar to the pillars that hold up two floors of a building, supporting the watch gears inside the watch case.',
      },
    ],
    C: [
      {
        word: 'Cabochon',
        definition:
          'Completely decorative with no useful purpose, the cabochon is a smooth round or oval gemstone often found set in the crown of a watch.',
      },
      {
        word: 'Calendar',
        definition:
          'A watch that indicates date of the month, day of the week, etc., as well as time.',
      },
      {
        word: 'Caliber',
        definition:
          'Is used to refer to the size, shape, style of a watch movement. Different watch manufacturers tend to use their own identification system to number their calibers.',
      },
      {
        word: 'Case (watch case)',
        definition: 'the metal case in which the works of a watch are housed.',
      },
      {
        word: 'Case Measurement',
        definition:
          'An approximate watch measurement from one end of the watch to the other that does not include the crown. On average, men\'s watches have a case width or diameter of 35 millimeters, which is just under 1.5 inches. Women\'s watches have a case width or diameter of 34 millimeters or smaller. The thickness measurement refers to the width between the case back and the top of the crystal.',
      },
      {
        word: 'Centre seconds',
        definition:
          'A hand at the center of the dial that indicates seconds, along with the hour and minute hands. It moves the fastest, it is also usually the thinnest of the three hands.',
      },
      {
        word: 'Chapter ring',
        definition:
          'A ring-like band on the dial of a clock that bears the Arabic numerals (1, 2, 3, 4, 5, 6, 7, 8, 9, 0), Roman numerals (I, II, III, IV, V, VI, VII, VIII, IX, X, XI, XII) or other symbols indicating the hour.',
      },
      {
        word: 'Chronograph',
        definition:
          'Is a watch that has a stopwatch function. It will have two or three additional registers/ recorders. The simplest chronographs have a register for 30 minutes, and a center-mounted second hand.',
      },
      {
        word: 'Chronometer',
        definition:
          'Is a high-precision watch displaying seconds whose movement has been tested over a period of several days, in different positions and at different temperatures, by an official neutral body. Movements that have been certified by the Official Swiss Chronometer Testing Institute (C.O.S.C)." .',
      },
      {
        word: 'Complications',
        definition:
          'Additional features beyond the simple display of hours, minutes, and seconds on a wrist watch. Features such as perpetual calendars, moon-phase displays, alarms, repeating mechanisms, and chronographs functions. A watch that is complicated usually consists of many parts, quite possibly, greater than a thousand parts.',
      },
      {
        word: 'C.O.S.C',
        definition:
          'Is the Official Swiss Chronometer Testing Institute (Contrôle Officiel Suisse des Chronomètres), which is responsible for testing and certifying the accuracy and precision of wristwatches in Switzerland.',
      },
      {
        word: 'Cosmograph',
        definition:
          'The cosmograph differs to the chronograph in that the tachymeter is on the bezel rather than on the outer rim of the dial. This was invented by Rolex to create a more modern look to the watch.',
      },
      {
        word: 'Countdown Timer',
        definition:
          'The counting backward aloud from an arbitrary starting number to indicate the time remaining before an event or operation.Some countdown timers sound a warning signal a few seconds before the time runs out. These are useful in sporting events, such as yacht races.',
      },
      {
        word: 'Crown (also referred to as a Winding Stem):',
        definition:
          'It is the button on the end of the spindle that is used for adjusting the time and/or date on an analogue (non digital) watch. Generally, the winding is done with the crown flush against the watch, pulled out once it adjusts the date (if it has one) and pulled out a second "click" it adjusts the time.',
      },
      {
        word: 'Crystal',
        definition:
          'A protective cover that protects the face of a watch, made of glass crystal, synthetic sapphire crystal, or plastic sapphire crystal, and is the most expensive and durable crystal, being approximately three (3) times harder than mineral crystals and twenty (20) times harder than acrylic crystals.',
      },
    ],
    D: [
      {
        word: 'Deployant Buckle:',
        definition:
          'A deployant buckle, also commonly referred to as a foldover, is a tri-folding clasp that secures the two ends of the bracelet while permitting enough space to slide the watch onto your wrist when opened.',
      },
      {
        word: 'Depth Alarm',
        definition:
          'An alarm watch that makes a sound when the wearer exceeds a preset depth level, usually divers wear watches with depth alarms.',
      },
      {
        word: 'Dial',
        definition:
          'The face of a watch. On the dial there is a chapter ring with numbers or symbols indicating the hour of the day in a clockwise fashion.',
      },
      {
        word: 'Digital',
        definition:
          'A digital display simply shows the time as a number, for example, 12:30 instead of a short hand pointing toward the number 12 and a long hand pointing toward the number 6. The digits are usually shown as a seven-segment display. In general, functions in digital watches work by push-buttons, either on the side of the watch case or on the surface of the watch itself.',
      },
      {
        word: 'Diving watch',
        definition:
          'A diving watch is a watch designed for underwater diving that features, at a minimum, a water resistance greater than 1.0 MPa (10 atm), the equivalent of 100 m (330 ft). The typical diving watch will have a water resistance of around 200m to 300m (660ft to 980ft), though modern technology allows the creation of diving watches that can go much deeper.',
      },
    ],
    E: [
      {
        word: 'Escapement',
        definition:
          'A mechanism consisting on an escape wheel and an anchor used to control movement of the wheel and to provide periodic energy impulses to a pendulum or balance.',
      },
    ],
    F: [
      { word: 'Face', definition: 'See Dial' },
      {
        word: 'Flyback Hand',
        definition:
          'A second hand on the chronograph that can be used to time laps or to determine finishing times for several competitors in race. When reset, the second hand flies back to zero very quickly.',
      },
    ],
    G: [
      {
        word: 'Grande Complications',
        definition:
          'The Grande Complication is the most complex watch you can find for a mechanical watch. It usually features such complications as perpetual calender, chronograph, and many more.',
      },
    ],
    H: [
      {
        word: 'Horology',
        definition:
          'The study and science of time, encompassing the art and craftsmanship of watchmaking.',
      },
    ],
    J: [
      {
        word: 'Jewel',
        definition:
          'A jewel in a watch is usually synthetic sapphires or rubies that act as bearings for gears in a mechanical watch on the bridge. The jewels reduce friction to make the watch more accurate and longer lasting.',
      },
    ],
    L: [
      {
        word: 'Lap Timer',
        definition:
          'A function on the chronograph that allows the wearer to time segments of a race. When finished you would reset the chronograph and it would go back to zero.',
      },
      {
        word: 'Lug(or Case Lug)',
        definition:
          'Projections on the watch dial to which the strap, band, or bracelet is attached.',
      },
      {
        word: 'Luminescence',
        definition:
          'Luminescent (glow in the dark) coating is usually found on the watch hand or markers, allowing them to illuminate automatically in a darker environment, so that the wearer will be able to read the watch at night.',
      },
    ],
    M: [
      {
        word: 'Mechanical Movement',
        definition:
          'A movement powered by a mainspring that works in conjunction with a balance wheel. The two types of mechanical movements on a watch are Automatic and Manual movement.',
      },
      {
        word: 'Minute Repeater',
        definition:
          'A watch which can additionally tell the time, at the push of a button or move of a small slide on the side of the case, by striking the hours, quarter hours and minutes since the last quarter hour on small goings inside the watch. Such complex watches are never inexpensive.',
      },
      {
        word: 'Moonphase Display',
        definition:
          'A graphic display on the face of the watch which indicates the phase of the moon. It is a complication that is always costly.',
      },
      {
        word: 'Movement',
        definition:
          'The inner mechanism of the watch that keeps time and moves the watch hands. The watch be an automatic, manual, quartz, or electric movemement.',
      },
    ],
    P: [
      {
        word: 'Push Buttons',
        definition:
          'Push buttons appear most commonly on a chronograph watch. The top button usually stops or starts the chronograph while the bottom button would reset the chronograph.',
      },
    ],
    Q: [
      {
        word: 'Quartz Movement',
        definition:
          'Quartz is a movement in a watch where the watch is powered by a quartz battery.',
      },
    ],
    R: [
      {
        word: 'Rotating Bezel',
        definition: 'A bezel that be turned. There are many differentiations of a rotating bezel.',
      },
      {
        word: 'Rotor',
        definition: 'A part of an automatic watch that winds the movements mainspring.',
      },
    ],
    S: [
      {
        word: 'Sapphire Crystal',
        definition:
          'A crystal made of synthetic sapphire, a crack resistant and scratch resistant substance that will protect the face of the watch.',
      },
      {
        word: 'Screw Lock Crown',
        definition:
          'A crown that can be screwed into the case of the watch which will make the watch watertight.',
      },
      {
        word: 'Shock Resistance',
        definition:
          'A watch\'s ability to withstand an impact equal to that of being dropped onto a wood floor from a height of 3 feet.',
      },
      {
        word: 'Stop watch(also referred to as a Chronograph)',
        definition: 'A watch that has hands that tell intervals of time.',
      },
      {
        word: 'Sub-dial',
        definition:
          'A small dial on a watch face used for any of several purposes, such as keeping track of elapsed minutes or hours on a chronograph or indicating the date.',
      },
    ],
    T: [
      {
        word: 'Tachymeter',
        definition: 'Measures the speed the wearer has gone over a certain distance.',
      },
      {
        word: 'Tang Buckle',
        definition:
          'A tang buckle is the most common strap found in todays market. A traditional loop and pin, it resembles a belt buckle.',
      },
      {
        word: 'Telemeter',
        definition:
          'A telemeter determines the distance of an object from the observer by measuring how long it takes sound to travel that distance.',
      },
      {
        word: 'Titanium',
        definition:
          'A metal that is used for some watch cases, bezels, and bracelets. It is much stronger than stainless steel and significantly lighter in weight.',
      },
      {
        word: 'Tourbillon',
        definition:
          'A tourbillon is one of the premier complications. It has a rotating cage to overcome the effects of gravity.',
      },
    ],
    W: [
      {
        word: 'Water Resistant',
        definition:
          'In broad terms, it means that the watch is able to resistant splashes of water, but if your watch indicates that it is water resistant up to 50m, it might be used for diving.',
      },
    ],
  }

  const hasDefinitions = letter => {
    return Object.prototype.hasOwnProperty.call(definitions, letter)
  }

  return (
    <Layout className={'mx-auto'} breadcrumbs={false} canonical="/glossary/">
      <Seo title="The Gray and Sons Glossary" description="Gray and Sons Glossary" canonical="/glossary/"/>
      <section>
        <h1 className=" text-black-700 my-5 text-center text-[calc(1rem+1vw)] uppercase">
          Glossary (Watch Terms) &amp; Definitions
        </h1>
      </section>
      <div className="container relative mx-auto flex short:flex-col flex-row gap-4 px-5 sm:text-justify md:px-10 md:text-justify lg:text-justify">
        <aside
          className={
            'short:order-1 short:grid short:grid-flow-row short:grid-cols-[repeat(14,1fr)] short:sm:flex short:h-auto short:top-0 short:w-full short:flex-row short:mb-2 short:bg-white ' +
            // "landscape:tall:order-2 landscape:tall:h-[95vh] landscape:tall:top-5 landscape:tall:w-4
            // landscape:tall:flex-col landscape:tall:mb-[250px] " +
            'sticky top-5 order-2 flex h-[95vh] w-8 flex-col justify-between md:h-[60vh] lg:mb-[250px]'
          }
        >
          {letters.map(letter => {
            return !hasDefinitions(letter) ? (
              <p className="h-10 text-center text-sm text-gray-400 lg:text-base">{letter}</p>
            ) : (
              <a
                href={'#' + letter}
                key={letter}
                className=" h-10 text-center text-sm font-bold text-red-700 duration-150 lg:hover:text-xl"
              >
                {letter}
              </a>
            )
          })}
        </aside>

        <section className="w-10/12 mx-auto text-red-700 order-1">
          Definitions
          {letters
            .filter(letter => hasDefinitions(letter))
            .map(letter => {
              return (
                <div key={letter}>
                  <h2 className=" border-grey-700 border-t border-b text-4xl font-black">
                    <a id={letter}>{letter}</a>
                  </h2>

                  {definitions[letter].map(def => {
                    return (
                      <div key={`${letter}-${def.word}`} className="my-4 w-full text-red-700  ">
                        {' '}
                        <b>{def.word}</b> -{' '}
                        <span className="my-4 text-stone-700">{def.definition}</span>
                      </div>
                    )
                  })}
                </div>
              )
            })}
        </section>
      </div>

      <div className="my-10" />
    </Layout>
  )
}

export default GlossaryPage
